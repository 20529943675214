import ToggableRefinementList from "components/screener/refinements/ToggableRefinementList";
import orderBy from "lodash/orderBy";
import { connectRefinementList } from "react-instantsearch-dom";
import { FormattedMessage } from "react-intl";

const ListingSegmentRefinementList = connectRefinementList(({ items, refine }) => {
  const listingSegments = orderBy(items, ["count"], ["desc"]);

  return (
    <div className="space-y-4">
      <h3 className="font-aeonik text-base font-bold uppercase tracking-wide text-zinc-500 dark:text-zinc-400">
        <FormattedMessage defaultMessage="Segmento de Listagem" id="djf3kt" />
      </h3>

      {listingSegments.length === 0 && (
        <p className="font-aeonik text-sm font-normal tracking-wide text-slate-500 dark:text-slate-400">
          <FormattedMessage defaultMessage="Nenhum segmento encontrado :(" id="h8yNd4" />
        </p>
      )}

      <ToggableRefinementList
        refine={refine}
        items={listingSegments.map((item) => {
          if (item.label === "Nível 1 de Governança Corporativa") {
            return {
              ...item,
              label: "Nível 1",
            };
          }

          if (item.label === "Nível 2 de Governança Corporativa") {
            return {
              ...item,
              label: "Nível 2",
            };
          }
          return item;
        })}
      />
    </div>
  );
});

export default function ListingSegment() {
  return <ListingSegmentRefinementList attribute="listingSegment" />;
}
