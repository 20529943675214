import classNames from "components/ui/classNames";
import { useAmplitude } from "lib/amplitude/Amplitude";
import { CheckIcon, RocketIcon } from "lucide-react";
import Link from "next/link";
import type { ReactNode } from "react";
import { FormattedMessage } from "react-intl";

interface FeatureBoxProps {
  displayOrigin: string;
  featureHighlights: string[];
  buttonText?: string;
  callToActionText?: ReactNode;
  className?: string;
}

export default function FeatureBox({
  displayOrigin,
  callToActionText,
  featureHighlights,
  buttonText,
  className,
}: FeatureBoxProps) {
  const amplitude = useAmplitude();

  return (
    <div
      className={classNames(
        "bg-brand-50 flex w-full flex-col space-y-4 border-t border-t-slate-200 pt-4 font-aeonik font-normal",
        "dark:border-t-slate-950/30",
        className,
      )}
    >
      <div className="flex flex-col items-center space-y-1">
        <div className="border-2 border-brand bg-brand/10 p-2 squircle">
          <RocketIcon strokeWidth={1.5} className="h-6 w-6 text-brand" />
        </div>

        <div>
          <h2 className="inline-flex px-4 py-1 text-xl font-bold italic tracking-wide text-brand">
            <FormattedMessage defaultMessage="Premium" id="C5xzTC" />
          </h2>
        </div>

        <em className="text-center text-sm tracking-wide text-gray-500 dark:text-slate-400">{callToActionText}</em>
      </div>

      {featureHighlights.length > 0 && (
        <div className="space-y-2">
          <ul className="space-y-2">
            {featureHighlights.map((line) => {
              return (
                <li key={line} className="flex items-center space-x-2">
                  <div className="rounded-full bg-violet-100 p-1 text-violet-600 dark:bg-violet-600/20 dark:text-violet-300">
                    <CheckIcon className="h-3 w-3" strokeWidth={3} />
                  </div>
                  <span className="text-sm font-medium tracking-wide text-slate-700 dark:text-violet-200">{line}</span>
                </li>
              );
            })}
          </ul>

          <p className="text-sm italic tracking-wide text-gray-500 dark:text-gray-400">
            <FormattedMessage defaultMessage="...e muito mais!" id="txGntR" />
          </p>
        </div>
      )}

      <Link
        href="/premium"
        as="/premium"
        passHref={true}
        className={classNames(
          "rounded-full bg-brand p-2 text-center text-sm font-medium tracking-wide text-white",
          "hover:bg-brand/80",
          "md:text-base",
          "dark:bg-teal-600/30 dark:text-teal-300 dark:hover:opacity-80",
        )}
        onClick={() => {
          amplitude.logEvent("Click on link to become Premium", {
            origin: displayOrigin,
          });
        }}
      >
        {buttonText || <FormattedMessage defaultMessage="Ver mais detalhes" id="MInQK8" />}
      </Link>
    </div>
  );
}
