import orderBy from "lodash/orderBy";
import ToggableRefinementList from "components/screener/refinements/ToggableRefinementList";
import { connectRefinementList } from "react-instantsearch-dom";
import { FormattedMessage } from "react-intl";

const RealEstateFundClassificationRefinementList = connectRefinementList(({ items, refine }) => {
  const classifications = orderBy(items, ["count"], ["desc"]);

  return (
    <div className="space-y-4 font-aeonik">
      <div className="space-y-1">
        <h3 className="font-aeonik text-base font-bold uppercase tracking-wide text-zinc-500">
          <FormattedMessage defaultMessage="Tipo" id="wt+XcA" />
        </h3>
        {classifications.length === 0 && (
          <p className="font-aeonik text-sm font-normal tracking-wide text-slate-500">
            <FormattedMessage defaultMessage="Nenhum tipo encontrado :(" id="gmFSEF" />
          </p>
        )}
      </div>

      {classifications.length > 0 && <ToggableRefinementList items={classifications} refine={refine} />}
    </div>
  );
});

export default function RealEstateFundClassification() {
  return <RealEstateFundClassificationRefinementList attribute="classification" />;
}
