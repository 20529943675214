import ToggableRefinementList from "components/screener/refinements/ToggableRefinementList";
import { MiniSearchInput } from "lib/miolo";
import deburr from "lodash/deburr";
import orderBy from "lodash/orderBy";
import { useEffect, useState } from "react";
import type { RefinementListProvided } from "react-instantsearch-core";
import { connectRefinementList } from "react-instantsearch-dom";
import classNames from "components/ui/classNames";
import { FormattedMessage, useIntl } from "react-intl";
import useInfinitePagination from "../../../lib/hooks/useInfinitePagination";
import Divider from "../Divider";

interface ICountryRefinementListProps {
  isChoosingCountry: boolean;
  onToggleChooseCountry: (isChoosingCountry: boolean) => void;
}

const CountryRefinementList = connectRefinementList<ICountryRefinementListProps & RefinementListProvided>(
  ({ isChoosingCountry, onToggleChooseCountry, items, refine }) => {
    const intl = useIntl();
    const options = orderBy(items, ["isRefined", "count", "label"], ["desc", "desc", "asc"]);

    const [searchQuery, setSearchQuery] = useState<string>("");

    const pagination = useInfinitePagination({
      itemsPerPage: 10,
      items: options.filter((item) => {
        if (searchQuery && searchQuery.trim()) {
          const cleanSearchQuery = searchQuery.trim();
          return deburr(item.label).toLowerCase().includes(deburr(cleanSearchQuery).toLowerCase());
        }

        return true;
      }),
    });

    useEffect(() => {
      if (searchQuery !== "") {
        pagination.reset();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery]);

    const refinements = items.filter((item) => item.isRefined);

    if (!isChoosingCountry) {
      return (
        <div className="space-y-4">
          <div className="space-y-1">
            <h3 className="font-aeonik text-base font-bold uppercase tracking-wide text-zinc-500">
              <FormattedMessage defaultMessage="País" id="M/hE4A" />
            </h3>

            {refinements.length > 0 && (
              <div className="flex items-center space-x-1">
                <span className="font-aeonik text-sm font-medium italic tracking-wide text-slate-500">
                  <FormattedMessage defaultMessage="Filtrando por" id="bNHElY" />
                </span>

                <strong className="rounded-xl border border-violet-300 bg-violet-50 px-2 py-0.5 text-xs font-bold text-violet-700">
                  <FormattedMessage
                    defaultMessage="({count, plural, one {# país} other {# países}})"
                    id="96A1Wy"
                    values={{
                      count: refinements.length,
                    }}
                  />
                </strong>
              </div>
            )}

            {refinements.length === 0 && (
              <div>
                <span className="flex font-aeonik text-sm font-normal italic tracking-wider text-slate-400">
                  <FormattedMessage defaultMessage="Filtre empresas por país." id="28uktr" />
                </span>
              </div>
            )}
          </div>

          <button
            type="button"
            className={classNames(
              "font-aeonik text-sm font-medium tracking-wide text-blue-600",
              "hover:underline",
              "disabled:text-slate-400",
              "dark:text-blue-300",
            )}
            onClick={() => onToggleChooseCountry(true)}
            disabled={items.length === 0}
          >
            {refinements.length > 0 ? (
              <FormattedMessage
                defaultMessage="Escolher outros países ({count})"
                id="mpv/8+"
                values={{
                  count: items.length,
                }}
              />
            ) : (
              <FormattedMessage
                defaultMessage="Escolher países ({count})"
                id="lfiAqi"
                values={{
                  count: items.length,
                }}
              />
            )}
          </button>
        </div>
      );
    }

    return (
      <div className="space-y-4">
        <div className="space-y-1">
          <h3 className="font-aeonik text-base font-medium uppercase tracking-wide text-slate-500">
            <FormattedMessage defaultMessage="País" id="M/hE4A" />
          </h3>
          <span className="flex font-aeonik text-sm font-normal tracking-wide text-slate-400">
            <FormattedMessage defaultMessage="Escolha um ou vários países." id="0HzwtE" />
          </span>
        </div>

        <MiniSearchInput
          value={searchQuery}
          placeholder={intl.formatMessage({
            defaultMessage: "Pesquisar",
            id: "keT/wF",
          })}
          onChange={(searchQuery) => setSearchQuery(searchQuery)}
        />

        {pagination.items.length > 0 && (
          <>
            <ToggableRefinementList items={pagination.items} refine={refine} />

            {pagination.hasMoreToShow && (
              <div className="text-center">
                <button
                  type="button"
                  onClick={pagination.showMore}
                  className="font-aeonik text-sm font-medium tracking-wide text-sky-600 hover:underline"
                >
                  <FormattedMessage defaultMessage="Ver mais países" id="oCXDTz" />
                </button>
              </div>
            )}

            <Divider variant="faded" />

            <div className="text-center">
              <button
                type="button"
                onClick={() => {
                  onToggleChooseCountry(false);
                  pagination.reset();
                }}
                className={classNames(
                  "w-full rounded-xl p-1.5 py-2 text-sm font-medium tracking-wide text-blue-600",
                  "hover:bg-slate-100 hover:text-slate-700",
                  "dark:text-blue-300 dark:hover:bg-slate-950 dark:hover:opacity-80",
                )}
              >
                <FormattedMessage defaultMessage="Ver todos os filtros" id="FkAt7o" />
              </button>
            </div>
          </>
        )}
      </div>
    );
  },
);

interface ICountryProps {
  isChoosingCountry: boolean;
  onToggleChooseCountry: (isChoosingCountry: boolean) => void;
}

export default function Country({ isChoosingCountry, onToggleChooseCountry }: ICountryProps) {
  return (
    <CountryRefinementList
      isChoosingCountry={isChoosingCountry}
      onToggleChooseCountry={onToggleChooseCountry}
      attribute="headquarters.countryName"
      limit={120}
    />
  );
}
