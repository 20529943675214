import { useMutation } from "@apollo/client";
import AddPositionToPortfolioMutation from "components/portfolio/mutations/AddPositionToPortfolioMutation";
import RemovePositionFromPortfolioByTickerSymbolMutation from "components/portfolio/mutations/RemovePositionFromPortfolioByTickerSymbolMutation";
import type {
  IAddPositionToPortfolioMutation,
  IAddPositionToPortfolioMutationVariables,
} from "components/portfolio/mutations/__generated__/AddPositionToPortfolioMutation.generated";
import type {
  IRemovePositionFromPortfolioByTickerSymbolMutation,
  IRemovePositionFromPortfolioByTickerSymbolMutationVariables,
} from "components/portfolio/mutations/__generated__/RemovePositionFromPortfolioByTickerSymbolMutation.generated";
import AssetPortfolioPositionsQuery from "components/portfolio/queries/AssetPortfolioPositionsQuery";
import PortfolioResultsCenterQuery from "components/portfolio/queries/PortfolioResultsCenterQuery";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/Popover";
import classNames from "components/ui/classNames";
import lightFormat from "date-fns/lightFormat";
import ToggleBookmarkOnIssuerMutation from "lib/graphql/mutations/ToggleBookmarkOnIssuerMutation";
import type {
  IToggleBookmarkOnIssuerMutation,
  IToggleBookmarkOnIssuerMutationVariables,
} from "lib/graphql/mutations/__generated__/ToggleBookmarkOnIssuerMutation.generated";
import BookmarksQuery from "lib/graphql/queries/BookmarksQuery";
import AgendaQuery from "lib/queries/AgendaQuery";
import noop from "lodash/noop";
import { ChevronDownIcon, PlusIcon } from "lucide-react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { FormattedMessage, useIntl } from "react-intl";
import type { ISecurityCountry } from "types/graphqlTypes";
import { IBookmarksOrder, IIssuerType, IOrderDirection } from "types/graphqlTypes";

interface CompanyActionsDropdownProps {
  issuerId: string;
  securityCountry: ISecurityCountry;
  tickerSymbol: string;
  viewerDidBookmark: boolean;
  isInPortfolio: boolean;
  iconContainerClassName?: string;
}

export default function CompanyActionsDropdown({
  issuerId,
  securityCountry,
  tickerSymbol,
  viewerDidBookmark,
  isInPortfolio,
  iconContainerClassName,
}: CompanyActionsDropdownProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const intl = useIntl();

  const [toggleBookmark] = useMutation<IToggleBookmarkOnIssuerMutation, IToggleBookmarkOnIssuerMutationVariables>(
    ToggleBookmarkOnIssuerMutation,
    {
      refetchQueries: [
        {
          query: BookmarksQuery,
          variables: {
            limit: 20,
            filterByIssuerType: null,
            sortField: IBookmarksOrder.CreatedAt,
            sortDirection: IOrderDirection.Ascending,
            cursor: null,
          },
        },
      ],
    },
  );

  const [addPositionToPortfolio] = useMutation<
    IAddPositionToPortfolioMutation,
    IAddPositionToPortfolioMutationVariables
  >(AddPositionToPortfolioMutation, {
    refetchQueries: [
      { query: PortfolioResultsCenterQuery },
      { query: AssetPortfolioPositionsQuery },
      {
        query: AgendaQuery,
        variables: {
          direction: IOrderDirection.Ascending,
          limit: 30,
          cursor: null,
          filterByIsInSnP500: false,
          filterByIssuersInPortfolio: true,
          filterByIssuersInBookmarks: false,
          filterByIssuerType: IIssuerType.BrazilianCompany,
          filterByStartDate: lightFormat(new Date(), "yyyy-MM-dd"),
          filterByEndDate: lightFormat(new Date(), "yyyy-MM-dd"),
          filterBySearchQuery: "",
          filterByEventType: null,
        },
      },
    ],
  });

  const [removePositionFromPortfolioByTickerSymbol] = useMutation<
    IRemovePositionFromPortfolioByTickerSymbolMutation,
    IRemovePositionFromPortfolioByTickerSymbolMutationVariables
  >(RemovePositionFromPortfolioByTickerSymbolMutation, {
    refetchQueries: [
      { query: PortfolioResultsCenterQuery },
      {
        query: AgendaQuery,
        variables: {
          direction: IOrderDirection.Ascending,
          limit: 30,
          cursor: null,
          filterByIsInSnP500: false,
          filterByIssuersInPortfolio: true,
          filterByIssuersInBookmarks: false,
          filterByIssuerType: IIssuerType.BrazilianCompany,
          filterByStartDate: lightFormat(new Date(), "yyyy-MM-dd"),
          filterByEndDate: lightFormat(new Date(), "yyyy-MM-dd"),
          filterBySearchQuery: "",
          filterByEventType: null,
        },
      },
    ],
  });

  return (
    <Popover open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
      <PopoverTrigger
        className={classNames(
          "flex cursor-pointer items-center justify-center space-x-1 rounded-md border border-transparent bg-gray-50 p-1 px-2 text-gray-500",
          "dark:bg-slate-950 dark:text-slate-400",
          iconContainerClassName,
        )}
        onClick={(event) => {
          event.preventDefault();
          setIsOpen((prev) => !prev);
        }}
      >
        <PlusIcon className="h-5 w-5" />
        <ChevronDownIcon className="h-3 w-3 fill-current" />
      </PopoverTrigger>

      <PopoverContent className="rounded-xl border border-gray-300 p-1.5 font-aeonik dark:border-slate-900">
        <ul
          role="menu"
          tabIndex={-1}
          className={classNames(
            "list-none space-y-1 text-sm font-medium text-slate-600 outline-none dark:text-slate-400",
          )}
        >
          <li role="menuitem">
            <button
              type="button"
              className={classNames(
                "inline-flex w-full rounded-md px-2 py-2",
                "hover:bg-violet-50 hover:text-violet-600",
                "dark:hover:bg-violet-600/20 dark:hover:text-violet-200",
              )}
              onClick={async (event) => {
                event.stopPropagation();
                await toast.promise(
                  toggleBookmark({
                    variables: {
                      issuerId,
                    },
                  }),
                  {
                    loading: viewerDidBookmark
                      ? intl.formatMessage({
                          defaultMessage: "Removendo dos favoritos...",
                          id: "a1wB3W",
                        })
                      : intl.formatMessage({
                          defaultMessage: "Adicionando aos favoritos...",
                          id: "0ZIa4S",
                        }),
                    success: viewerDidBookmark
                      ? intl.formatMessage({
                          defaultMessage: "Removido dos favoritos!",
                          id: "RfW0+2",
                        })
                      : intl.formatMessage({
                          defaultMessage: "Adicionado aos favoritos!",
                          id: "RgyfUM",
                        }),
                    error: intl.formatMessage({
                      defaultMessage: "Algo deu errado!",
                      description: "Mensagem genérica de erro",
                      id: "hzLiLu",
                    }),
                  },
                );

                setIsOpen(false);
              }}
            >
              {viewerDidBookmark ? (
                <FormattedMessage defaultMessage="Remover dos favoritos" id="yBrZIE" />
              ) : (
                <FormattedMessage defaultMessage="Adicionar nos favoritos" id="9UTjUk" />
              )}
            </button>
          </li>

          <li role="menuitem">
            <button
              type="button"
              className={classNames(
                "inline-flex w-full rounded-md px-2 py-2",
                "hover:bg-violet-50 hover:text-violet-600",
                "dark:hover:bg-violet-600/20 dark:hover:text-violet-200",
              )}
              onClick={async (event) => {
                event.stopPropagation();
                if (isInPortfolio) {
                  await toast.promise(
                    removePositionFromPortfolioByTickerSymbol({
                      variables: {
                        tickerSymbol,
                      },
                    }),
                    {
                      loading: intl.formatMessage({
                        defaultMessage: "Removendo da carteira...",
                        id: "PkC08x",
                      }),
                      success: intl.formatMessage({
                        defaultMessage: "Removido da carteira!",
                        id: "Gnl9iC",
                      }),
                      error: intl.formatMessage({
                        defaultMessage: "Algo deu errado!",
                        description: "Mensagem genérica de erro",
                        id: "hzLiLu",
                      }),
                    },
                  );
                } else {
                  await toast.promise(
                    addPositionToPortfolio({
                      variables: {
                        input: {
                          securityCountry,
                          tickerSymbol,
                          numberOfShares: 0,
                          goal: 0,
                        },
                      },
                    }).catch(noop),
                    {
                      loading: intl.formatMessage({
                        defaultMessage: "Adicionando na carteira...",
                        id: "ORorbT",
                      }),
                      success: intl.formatMessage({
                        defaultMessage: "Adicionado na carteira!",
                        id: "R7VL4K",
                      }),
                      error: intl.formatMessage({
                        defaultMessage: "Algo deu errado!",
                        description: "Mensagem genérica de erro",
                        id: "hzLiLu",
                      }),
                    },
                  );
                }

                setIsOpen(false);
              }}
            >
              {isInPortfolio ? (
                <FormattedMessage defaultMessage="Remover da carteira" id="0Ujyur" />
              ) : (
                <FormattedMessage defaultMessage="Adicionar na carteira" id="L1CXQw" />
              )}
            </button>
          </li>
        </ul>
      </PopoverContent>
    </Popover>
  );
}
